<template>
  <div
    :class="badgeClass(item)"
    :style="badgeStyle(item)"
  >
    <span
      class="product-label__badge_left"
      :style="borderStyle(item, 'Right')"
    />
    <span
      class="product-label__badge_right"
      :style="borderStyle(item, 'Left')"
    />
    <span>{{ item.text }}</span>
  </div>
</template>

<script>
export default {
  name: 'DefaultBadge',
  props: {
    item: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    badgeClass () {
      return (item) => `product-label__badge product-label__badge-position-${item.position}`;
    },
    badgeStyle () {
      return (item) => item && item.color ? { backgroundColor: item.color } : {};
    },
    borderStyle () {
      return (item, side) => ({ [`border${side}`]: `var(--spacer-4) solid ${item.color}` });
    }
  }
};
</script>
